<template>
  <div class="px-3 py-2">
    <v-form ref="forcar" lazy-validation>
      <b-sidebar
        id="update_crmtask"
        aria-labelledby="sidebar-no-header-title"
        no-header
        backdrop
        width="700px"
        style="direction: ltr"
        right
        title="تحديث مهمة"
        shadow
      >
        <template #default="{ hide }">
          <div class="m-1 text-start" style="direction: rtl">
            <div
              style="
                width: 120px;
                margin-right: 10px;
                background: #000;
                color: #fff;
                border-radius: 5px;
              "
              class="btn-primary btn-sm text-center"
            >
              <span>{{ lang.update_task }}</span>
            </div>
            <div
              @click="hide"
              id="hideTaskUpdate"
              style="
                width: 100px;
                text-align: center;
                float: left;
                margin-left: 5px;
                margin-top: -25px;
                cursor: pointer;
              "
              class="btn-danger btn-sm"
            >
              <span>{{ lang.close }}</span>
            </div>
          </div>
          <v-divider></v-divider>
          <v-row style="direction: rtl" class="m-2">
            
            <v-col cols="12" md="12" sm="12">
              <label>{{ lang.agent }}</label>
              <b-input-group>
                <b-form-select
                  class="selborder"
                  v-model="task_response"
                  :options="responses"
                ></b-form-select>
                <b-input-group-addon style="margin-right: -20px; margin-top: 5px">
                  <i class="fas fa-arrow-down"></i>
                </b-input-group-addon>
              </b-input-group>
            </v-col>
            <v-col cols="12" md="12" sm="12">
              <label>{{ lang.status }}</label>
              <b-input-group>
                <b-form-select
                  class="selborder"
                  v-model="status"
                  :options="taskStatus"
                ></b-form-select>
                <b-input-group-addon style="margin-right: -20px; margin-top: 5px">
                  <i class="fas fa-arrow-down"></i>
                </b-input-group-addon>
              </b-input-group>
            </v-col>

            <v-col cols="12" md="12" sm="12">
              <label>{{ lang.task_description }}</label>
              <b-form-textarea
                class="inborder"
                v-model="notes"
                value=""
                required
              ></b-form-textarea>
            </v-col>
            <v-col cols="12" md="12" sm="12">
                <v-simple-table>
                    <thead>
                        <tr>
                            <th class="backBlack" colspan="4">{{ lang.notes }}</th>
                        </tr>
                    </thead>
                    <tbody v-for="(item,index) in taskDet" :key="index">
                        <tr>
                            <th class="backBlack" style="width:100px;">{{ lang.added_by }}</th>
                            <td>{{ item.added_by }}</td>
                            <th class="backBlack" style="width:100px;">{{ lang.added_date }}</th>
                            <td style="width:100px;">{{ item.added_date }}</td>
                        </tr>
                        <tr>
                            <td colspan="4">{{ item.notes }}</td>
                        </tr>
                    </tbody>
                </v-simple-table>
            </v-col>
          </v-row>
        </template>
        <template #footer="" class="shadow">
          <div class="d-flex text-light align-items-center px-3 py-2">
            <b-button
              type="button"
              @click="addNote()"
              variant="success"
              class="ma-2"
              style="width: 100px"
              >{{ lang.add }}</b-button
            >
          </div>
        </template>
      </b-sidebar>
    </v-form>
  </div>
</template>

<script>
import axios from "axios";
import { SnotifyPosition } from "vue-snotify";
export default {
  data() {
    return {
      notes: "",
      crm_id: 0,
      task_response: 1,
      users: [],
      status: 1,
      statuss: 1,
      taskDet: [],
    };
  },
  computed: {
    lang: function () {
      if (this.$cookies.get(this.$store.state.LangCooki) != null) {
        if (this.$cookies.get(this.$store.state.LangCooki) == "en") {
          return this.$store.state.lang.en;
        } else {
          return this.$store.state.lang.ar;
        }
      } else if (this.$store.state.deflang == "en") {
        return this.$store.state.lang.en;
      } else {
        return this.$store.state.lang.ar;
      }
    },

    responses: function () {
      let t = [];
      for (let i = 0; i < this.users.length; i++) {
        t.push({ text: this.users[i].full_name, value: this.users[i].id });
      }
      return t;
    },
    taskStatus: function () {
      let t = [];
      for (let i = 0; i < this.statuss.length; i++) {
        let text =
          this.lang.langname == "ar" ? this.statuss[i].name_ar : this.statuss[i].name_en;
        t.push({ text: text, value: this.statuss[i].value });
      }
      return t;
    },
  },
  methods: {
    getTaskDet(){
        const post = new FormData();
            post.append('defBranch',localStorage.getItem('defBranch'));
          post.append('financeYear',localStorage.getItem('financeYear'));
        post.append("type", "getTaskDet");
        post.append("auth", this.$cookies.get(this.$store.state.COOKIEPhase));
        post.append("data[id]", this.crm_id);
        axios.post(this.$SAMCOTEC.r_path, post).then((response) => {
           
            if (response && response.data && response.data.results) {
                this.taskDet = response.data.results.data;
            }
        });
    },
    getUsers() {
      const post = new FormData();
            post.append('defBranch',localStorage.getItem('defBranch'));
          post.append('financeYear',localStorage.getItem('financeYear'));
      post.append("type", "getUsers");
      post.append("auth", this.$cookies.get(this.$store.state.COOKIEPhase));
      post.append("data[crm_id]", this.crm_id);
      axios.post(this.$SAMCOTEC.r_path, post).then((response) => {
        // console.log(response.data);
        if (response && response.data && response.data.results) {
          this.users = response.data.results.data.results;
        }
      });
    },
    getStatics() {
      const post = new FormData();
            post.append('defBranch',localStorage.getItem('defBranch'));
          post.append('financeYear',localStorage.getItem('financeYear'));
      post.append("type", "getStaticOptions");
      post.append("auth", this.$cookies.get(this.$store.state.COOKIEPhase));
      post.append("data[phrase]", "taskStatus");
      axios.post(this.$SAMCOTEC.r_path, post).then((response) => {
        // console.log(response.data);
        if (response && response.data && response.data.results) {
          this.statuss = response.data.results.data;
        }
      });
    },
    addNote() {
      if (this.notes == "") {
        let message = "يجب ان تقوم باضافة وصف المهمة";
        this.$snotify.error(message, "تنبيه", {
          timeout: 1000000,
          showProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          position: SnotifyPosition.centerCenter,
          buttons: [
            {
              text: this.$parent.lang.close,
              action: (toast) => {
                this.$snotify.remove(toast.id);
              },
            },
          ],
        });
        return false;
      }

      const post = new FormData();
            post.append('defBranch',localStorage.getItem('defBranch'));
          post.append('financeYear',localStorage.getItem('financeYear'));
      post.append("type", "UpdateNewTaskToCRM");
      post.append("auth", this.$cookies.get(this.$store.state.COOKIEPhase));
      post.append("data[id]", this.crm_id);
      post.append("data[task_text]", this.notes);
      post.append("data[task_response]", this.task_response);
      post.append("data[status]", this.status);
      axios.post(this.$store.state.SAMCOTEC.r_path, post).then((response) => {
        const res = response.data;
        let message = "تم تعديل المهمة بنجاح";
        this.$snotify.error(message, "تنبيه", {
          timeout: 1000000,
          showProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          position: SnotifyPosition.centerCenter,
          buttons: [
            {
              text: this.$parent.lang.close,
              action: (toast) => {
                this.$snotify.remove(toast.id);
              },
            },
          ],
        });
        this.$parent.getCRMData(this.crm_id);
        document.getElementById("hideTaskUpdate").click();
        
      });

      this.message = "";
    },
  },
  created() {
    this.getUsers();
    this.getStatics();
  },
};
</script>
